var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-button",
        {
          attrs: { prefix: "add-one" },
          on: {
            click: function ($event) {
              _vm.modal = true
            },
          },
        },
        [_vm._v(" 选择部门和员工 ")]
      ),
      _vm.perDataList
        ? _c(
            "div",
            { staticClass: "scopeList" },
            [
              _vm._l(
                _vm.perDataList.length > 6 ? 6 : _vm.perDataList.length,
                function (i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "person" },
                    [
                      _c("CbAvater", {
                        attrs: {
                          "on-job-id": _vm.perDataList[i - 1].onJobId,
                          "avater-name": _vm.perDataList[i - 1].staffName,
                          "default-name-length": 1,
                          size: "22",
                        },
                      }),
                      _c(
                        "svg",
                        {
                          staticClass: "iconpark-icon close",
                          on: {
                            click: function ($event) {
                              return _vm.delUser(_vm.perDataList[i - 1].onJobId)
                            },
                          },
                        },
                        [_c("use", { attrs: { href: "#close-small" } })]
                      ),
                    ],
                    1
                  )
                }
              ),
              _c(
                "a-popover",
                {
                  attrs: {
                    title: "适用人员",
                    placement: "left",
                    trigger: "hover",
                    "overlay-class-name": "thePopover",
                  },
                },
                [
                  _c("template", { slot: "content" }, [
                    _c(
                      "div",
                      { staticClass: "scopeList popoverContent" },
                      _vm._l(_vm.perDataList, function (item) {
                        return _c(
                          "div",
                          { key: item.onJobId, staticClass: "person" },
                          [
                            _c("CbAvater", {
                              attrs: {
                                "on-job-id": item.onJobId,
                                "avater-name": item.staffName,
                                "default-name-length": 1,
                                size: "22",
                                width: "55",
                              },
                            }),
                            _c(
                              "svg",
                              {
                                staticClass: "iconpark-icon close",
                                on: {
                                  click: function ($event) {
                                    return _vm.delUser(item.onJobId)
                                  },
                                },
                              },
                              [_c("use", { attrs: { href: "#close-small" } })]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.perDataList.length > 6,
                          expression: "perDataList.length > 6",
                        },
                      ],
                      staticClass: "num",
                    },
                    [_vm._v("+" + _vm._s(_vm.perDataList.length - 6))]
                  ),
                ],
                2
              ),
            ],
            2
          )
        : _vm._e(),
      _c("PerModelSelect", {
        attrs: {
          "query-leave-person": _vm.queryLeavePerson,
          placeholder: "请输入部门、人员、工号",
          "data-echo": _vm.dataEcho,
        },
        on: { perModelConfirm: _vm.perModelConfirm },
        model: {
          value: _vm.modal,
          callback: function ($$v) {
            _vm.modal = $$v
          },
          expression: "modal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }